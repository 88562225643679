import React, { Component } from "react";
import { Grid } from "@mui/material";
import { Formik, Field, Form, ErrorMessage } from "formik";
import FormControlLabel from "@mui/material/FormControlLabel";
import { TextField, CheckboxWithLabel, RadioGroup } from "formik-mui";
import Button from "@mui/material/Button";
import { Radio } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";


class CheckinOTAForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCheckedIn: false,
    };

    this.callsignInput = null;

    this.setCallsignInputRef = (element) => {
      this.callsignInput = element;
    };

    this.focusCallsignInput = () => {
      // Focus the text input using the raw DOM API
      if (this.callsignInput) {
        // so this is finicky and i found zero documentation... just fished around the object model.
        // if you change the OTA checkin form, this has to be revisited.
        this.callsignInput.children[1].children[0].focus();
      }
    };
  }

  componentDidMount() {
    if (this.props.editMode) {} else {
      this.focusCallsignInput()
    }
  }

  validate_form(values) {
    const errors = {};
    if (!values.callsign) {
      errors.callsign = "Callsign is required";
    }
    if (!values.name) {
      errors.name = "Name is required";
    }
    if (!values.via) {
      errors.via = "The connection mode is required.";
    }
    return errors;
  }

  render() {
    const form_submit = (values, { setSubmitting, resetForm }) => {
      values["sessionid"] = this.props.sessionid;
      values["isSuggestion"] = false


      try {
        this.props.editMode ?
          this.props.websocket_api("netSessionCheckinEdit", JSON.stringify(values, null, 2)) : 
          this.props.websocket_api("netSessionCheckin", JSON.stringify(values, null, 2))
      } catch (error) {
        console.log(error);
        return;
      }
      resetForm({});
      setSubmitting(false);
      if (this.props.editMode) {
        this.props.toggleEdit.toggleEdit(this.props.callsign) // FIXME with this nested thing.
      } else {
        this.focusCallsignInput()
      }
    };

    return (
      <div>
        <Card><CardContent>
          <Formik
            initialValues={{
              callsign: this.props.callsign || "",
              name: this.props.name || "",
              location: this.props.location || "",
              short_time: this.props.short_time || false,
              via: this.props.via || "Hotspot",
              comments: this.props.comments || "",
            }}
            validate={this.validate_form}
            onSubmit={form_submit}
          >
            {({ isSubmitting, errors, touched }) => (
              <Form>
                <Grid container spacing={1}>
                  <Grid item>
                    <Field
                      name="callsign"
                      label="Callsign"
                      component={TextField}
                      innerRef={this.setCallsignInputRef}
                      disabled={this.props.editMode}
                    />
                  </Grid>
                  <Grid item>
                    <Field name="name" label="Name" component={TextField} />
                  </Grid>
                  <Grid item>
                    <Field
                      name="location"
                      label="Location"
                      component={TextField}
                    />
                  </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                  <Grid item>
                    <Field component={RadioGroup} row name="via">
                      <FormControlLabel
                        value="Hotspot"
                        control={<Radio />}
                        label="Hotspot"
                        disabled={isSubmitting}
                      />
                      <FormControlLabel
                        value="Repeater"
                        control={<Radio />}
                        label="Repeater"
                        disabled={isSubmitting}
                      />
                      <FormControlLabel
                        value="Network Radio"
                        control={<Radio />}
                        label="Network Radio"
                        disabled={isSubmitting}
                      />
                    </Field>
                  </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                  {/*<Grid item>
                    <Field
                      name="comments"
                      label="Comments"
                      component={TextField}
                    />
                  </Grid>*/}
                  <Grid item>
                    <Field
                      name="short_time"
                      type="checkbox"
                      component={CheckboxWithLabel}
                      Label={{ label: "Short Time" }}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      variant="contained"
                      color="primary"
                    >
                      Submit
                    </Button>
                  </Grid>
                  { this.props.editMode ? 
                    <Grid item>
                      <Button
                        type="cancel"
                        disabled={isSubmitting}
                        variant="contained"
                        color="secondary"
                        onClick={(event) => {
                          event.preventDefault();
                          this.props.toggleEdit.toggleEdit(this.props.callsign)} // FIXME with the nested thing
                        }
                      >
                        Cancel
                      </Button>
                      </Grid>
                    : null
                  }

                </Grid>
              </Form>
            )}
          </Formik>
          </CardContent></Card>       
      </div>
    );
  }
}

export default CheckinOTAForm;
