import React, { Component } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Avatar from "@mui/material/Avatar";
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna'; // repeater
import RouterIcon from '@mui/icons-material/Router'; // hotspot
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'; // network radio
import TimerIcon from '@mui/icons-material/Timer'; // short time
import EditIcon from "@mui/icons-material/Edit"; // admin edit
import DeleteIcon from "@mui/icons-material/Delete"; // admin delete
import InfoIcon from "@mui/icons-material/Info"; // mouseover for stats
import Tooltip from '@mui/material/Tooltip';
import CheckinOTAForm from "./CheckinOTAForm";
import Typography from '@mui/material/Typography';


function CheckinEditRow(props) {
  const {
    callsign,
    name,
    location,
    via,
    short_time,
    comments,
    image,
    isSuggestion
  } = props.row;
  const toggleEdit = props.toggleEdit.toggleEdit // FIXME: what is this all about? the function is buried a layer deeper with the same name? it works.
  const showSuggestions = props.showSuggestions
  
  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <CheckinOTAForm editMode={true} toggleEdit={{toggleEdit}} websocket_api={props.websocket_api} sessionid={props.sessionid} callsign={callsign} name={name} location={location} via={via} short_time={short_time} comments={comments} />
        </TableCell>        
      </TableRow>
    </React.Fragment>
  );

}


function CheckinRow(props) {
  const {
    callsign,
    name,
    location,
    via,
    short_time,
    comments,
    image,
    isSuggestion,
    stats
  } = props.row;
  const toggleEdit = props.toggleEdit.toggleEdit // FIXME: what is this all about? the function is buried a layer deeper with the same name? it works.
  const isNetOperator = props.isNetOperator
  const showSuggestions = props.showSuggestions

  const statsText = stats ?
    <div>
      {callsign}<br/>
      QSOs: {stats.num_qsos}<br/>
      Contacts: {stats.num_contacts}
    </div> :
    'no stats available'

  let editbutton = null
  if (isNetOperator) {
    editbutton =
    <TableCell align="left">
      <Tooltip title="Edit checkin"><EditIcon color="secondary" onClick={() => { toggleEdit(callsign) }} /></Tooltip>
      <Tooltip title="Delete checkin"><DeleteIcon color="secondary" onClick={() => {props.websocket_api('netSessionCheckinDelete', '{"callsign": "'+callsign+'", "sessionid": "'+props.sessionid+'"}');}} /></Tooltip>
    </TableCell>
  }

  if (isSuggestion !== showSuggestions) return (null) // only show the "suggested" rows if our isSuggested flag is set

  return (
    <React.Fragment>
      <TableRow>
        <TableCell align="left"><Avatar src={image} alt={name} style={{width: 25, height: 25}}>{name.substr(0,1)}</Avatar></TableCell>
        <TableCell align="left"><div style={{display:'flex', alignItems:'center', flexWrap:'wrap'}}>{callsign}&nbsp;<Tooltip title={statsText}><InfoIcon fontSize='small' color='disabled'/></Tooltip></div></TableCell>
        <TableCell align="left">{name}</TableCell>
        <TableCell align="left">{location}</TableCell>
        <TableCell align="left">
          {via === "Repeater" ? <Tooltip title={via}><SettingsInputAntennaIcon /></Tooltip> : null}
          {via === "Hotspot" ? <Tooltip title={via}><RouterIcon /></Tooltip> : null}
          {via === "Network Radio" ? <Tooltip title={via}><PhoneAndroidIcon /></Tooltip> : null}
          {short_time ? <Tooltip title="Short time"><TimerIcon /></Tooltip> : null}
        </TableCell>
        <TableCell align="left">{comments}</TableCell>
        {editbutton}
      </TableRow>
    </React.Fragment>
  );
}

class CheckinsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkins: [],
    };    
  }


  componentDidUpdate(prevprops) {
    /* we have an update... let's see if it's a checkins update and apply it to state */
    if (this.props.checkins !== this.state.checkins) {
      this.setState({ checkins: this.props.checkins });
    }
  }


  render() {

    let toggleEdit = (callsign) => {
      let update = {}
      if (this.state['editToggle' + callsign] === undefined || this.state['editToggle' + callsign] === false) {
        update['editToggle' + callsign] = true
      } else update['editToggle' + callsign] = false
      this.setState(update)
    }
  
    return (
      <div>
        <TableContainer>
          {this.props.isSuggested && this.state.checkins.some(c => c.isSuggestion) ?
            <div>
              <br/>
              &nbsp;&nbsp;<Typography variant="overline">Suggested Checkins:</Typography>
            </div> :
            null
          }

          <Table style={{minWidth: 20}} size="small">
            <TableBody>
              {this.state.checkins.map((c) => {
                if (this.state['editToggle' + c.callsign] === true) {
                  return <CheckinEditRow websocket_api={this.props.websocket_api} key={c.callsign} sessionid={this.props.sessionid} showSuggestions={this.props.isSuggested} row={c} toggleEdit={{toggleEdit}} isNetOperator={this.props.isNetOperator} />;
                } else {
                  return <CheckinRow websocket_api={this.props.websocket_api} key={c.callsign} sessionid={this.props.sessionid} showSuggestions={this.props.isSuggested} row={c} toggleEdit={{toggleEdit}} isNetOperator={this.props.isNetOperator} />;
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default CheckinsTable;
