import React from "react";
import { AppBar, Avatar, Toolbar, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Navbar from "./Navbar";

const ProfileMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    props.logout({ returnTo: window.location.origin });
  };

  return (
    <div>
      <Avatar
        onClick={handleClick}
        alt={
          props.profile
            ? props.profile.name
              ? props.profile.name.substr(0, 1)
              : null
            : null
        }
        src={
          props.profile
            ? props.profile.image
              ? props.profile.image
              : null
            : null
        }
      />
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  );
};

/* Login and Logout buttons from Auth0 */
const LogInOutButton = (props) => {
  const { isAuthenticated, isLoading, loginWithRedirect, logout } = props.auth0;

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return isAuthenticated ? (
    <ProfileMenu
      logout={logout}
      profile={props.profile ? props.profile : null}
    />
  ) : (
      <Chip
        size="small"
        label="Log In"
        color="primary"
        onClick={loginWithRedirect}
      />
  );
};

class AppHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <AppBar position="static" style={{ background: "#000000" }}>
        <Toolbar>
          <Typography variant="h6">
            <Link to="/" style={{ textDecoration: "none", color: "#ccc" }}>
              Net
            </Link>
          </Typography>
          <Typography variant="h6">
            <Link to="/" style={{ textDecoration: "none", color: "#fff" }}>
              QSO
            </Link>
          </Typography>
          <div style={{ flex: 1 }} />
          <Navbar />
          <div style={{ flex: 1 }} />
          {LogInOutButton(this.props)}
        </Toolbar>
      </AppBar>
    );
  }
}

export default AppHeader;
