import React, { Component } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link, withRouter } from "react-router-dom";


class NavBar extends Component {

    render() {

      const pathname = this.props.history.location.pathname + '/'
      const pathname_prefix = '/' + pathname.split('/')[1]
 
      
      return (
          <div>
            <Tabs value={pathname_prefix} indicatorColor='primary'>
                <Tab label={<span style={{color: 'white'}}>Home</span>} component={Link} to="/" value="/" hidden={true} />
                <Tab label={<span style={{color: 'white'}}>Nets</span>} component={Link} to="/nets" value="/nets" />
                <Tab label={<span style={{color: 'white'}}>Logs</span>} component={Link} to="/logs" value="/logs" />
                <Tab label={<span style={{color: 'white'}}>Contests</span>} component={Link} to="/contests" value="/contests" />
            </Tabs>
          </div>
        );
    
    }
}

export default withRouter(NavBar)