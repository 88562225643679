import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Toolbar from "@mui/material/Toolbar";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import RecordVoiceOverTwoToneIcon from "@mui/icons-material/RecordVoiceOverTwoTone";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
var _ = require("lodash");

function Row(props) {
  const { row, session_button } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <div style={{ display: "flex", alignItems: "center" }}>
            <Box m={1}>
              <Avatar alt={row.name} src={row.image} />
            </Box>
            {row.name}
            <br />
            {row.mode}{" "}{row.freq}
            <br />
            {row.schedule.dow}{" "}{row.schedule.time}
          </div>
        </TableCell>
        <TableCell align="left">
          {row.active_session !== null ? (
            <Chip
              icon={<RecordVoiceOverTwoToneIcon />}
              label="VIEW"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                props.history.push(`/nets/session/${row.active_session}`);
              }}
            />
          ) : null}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="caption" gutterBottom component="div">
                {row.desc}
              </Typography>
              <div>{session_button}</div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

class NetList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      netList: {},
    };
  }

  componentDidUpdate(prevprops) {
    /* we have an update... let's see if it's a netList update and apply it to state */
    if (this.props.netList !== prevprops.netList) {
      const updated = _.merge(this.state.netList, this.props.netList);
      this.setState({ netList: updated });
    }
  }

  componentDidMount() {
    /* send netlist request as soon as we load */
    try {
      this.props.websocket_api("netList", '{ "filter": "none" }');
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    this.isNetOperator = (owner, netops, admins, myuserid) => {
      if (
        myuserid === owner ||
        netops.includes(myuserid) ||
        admins.includes(myuserid)
      ) {
        return true;
      }
      return false;
    };

    this.sessionButton = (
      active_session,
      netid,
      owner,
      netops,
      admins,
      myuserid
    ) => {
      if (!myuserid || !this.isNetOperator(owner, netops, admins, myuserid))
        return null;
      if (active_session === null) {
        return (
          <Button
            variant="contained"
            onClick={() => {
              this.props.websocket_api(
                "netSessionOpen",
                `{ "netid": "${netid}" }`
              );
            }}
          >
            Open Session
          </Button>
        );
      } else {
        return (
          <Button
            variant="contained"
            onClick={() => {
              this.props.websocket_api(
                "netSessionClose",
                `{ "netid": "${netid}" }`
              );
            }}
          >
            Close Session
          </Button>
        );
      }
    };

    /* check props for incoming netlist updates */
    if (this.state.netList) {
      const nets = this.state.netList;
      return (
        <Paper>
          <Toolbar>
            <Typography>All Nets</Typography>
          </Toolbar>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {Object.keys(nets).map((netid, index) => {
                  return (
                    <Row
                      key={netid}
                      row={nets[netid]}
                      session_button={this.sessionButton(
                        nets[netid].active_session,
                        netid,
                        nets[netid].owner,
                        nets[netid].netops,
                        nets[netid].admins,
                        this.props.profile && this.props.profile.userid
                          ? this.props.profile.userid
                          : null
                      )}
                      history={this.props.history}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      );
    }

    return null;
  }
}

export default withRouter(NetList);
