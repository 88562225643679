import { Typography } from "@mui/material";
import React from "react";
import Link from "@mui/material/Link";

function Footer() {
  return (
    <div align='center'>
        <Typography>
          <Link underline='none' href="https://app.termly.io/document/privacy-policy/a951eeec-c232-4ee3-8aff-f5e103bcac53" target="_blank" rel="noreferrer">
              Privacy
          </Link>
          {" | "}
          <Link underline='none' href="https://app.termly.io/document/terms-of-use-for-website/e0c7baeb-404a-4301-ade6-05264aaa43be" target="_blank" rel="noreferrer">
              Terms
          </Link>
          {" | "}
          <Link underline='none' href="https://app.termly.io/document/cookie-policy/dcc10343-ea7a-4e5e-b5eb-9b902eaf4d9b" target="_blank" rel="noreferrer">
              Cookies
          </Link>
        </Typography>
    </div>
  );
}

export default Footer;
