import React, { Component } from "react";
import {
  Button,
  IconButton,
  Toolbar,
  Paper,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import { Box, Collapse, Switch, Chip, Tabs, Tab } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import ChatIcon from "@mui/icons-material/Chat";
import TimelineIcon from "@mui/icons-material/Timeline";

import { withRouter } from "react-router-dom";
import CheckinOTAForm from "./CheckinOTAForm";
import CheckinsTable from "./CheckinsTable";
import CheckinSelfForm from "./CheckinSelfForm";
var _ = require("lodash");

class NetSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: "roster",
      showNetOpPanel: false,
      showSelfCheckin: false,
      didCheckIn: false,
      sessionid: "",
      isLoading: true,
      netSession: {
        ts_open: 0,
        checkins: [],
      },
      message: "",
      netSessionChat: null,
      stream: [],
    };
  }

  upsert(array, item) {
    const i = array.findIndex(_item => _item.callsign === item.callsign);
    if (i > -1) array[i] = item;
    else array.push(item);
  }

  componentDidUpdate(prevprops) {
    /* we have an update... let's see if it's a netSession update and apply it to state */

    if (this.props.netSession !== prevprops.netSession) {
      const updated = _.merge(this.state.netSession, this.props.netSession);
      this.setState({ netSession: updated });
    }

    if (this.props.netSessionCheckin !== prevprops.netSessionCheckin) {
      let updated_checkins = this.state.netSession.checkins;
      this.upsert(updated_checkins, this.props.netSessionCheckin.checkin)
      updated_checkins.sort((a, b) => a.utc_ts > b.utc_ts ? 1 : -1) // re-sort the list to account for suggestions being promoted
      this.setState({ netSession: { checkins: updated_checkins } });
    }

    if (this.props.netSessionCheckinDelete !== prevprops.netSessionCheckinDelete) {
      let updated_checkins = this.state.netSession.checkins;
      _.remove(updated_checkins, { callsign: this.props.netSessionCheckinDelete.callsign })
      this.setState({ netSession: { checkins: updated_checkins } });
    }

    if (this.props.dmrHoseline !== prevprops.dmrHoseline) {
      this.setState({ dmrHoseline: this.props.dmrHoseline });
      
      const tg = Object.values(this.props.dmrHoseline)[0];
      
      if (tg != null) {
        const ts = Math.round(Date.now() / 1000)
        const obj = JSON.parse('{ "type": "transmit", "ts": ' + ts + ', "callsign": "' + tg.callsign + '"}')
        let updated_stream = this.state.stream.concat([obj]);
        this.setState({ stream: updated_stream });
      }
    }

    if (this.props.netStreamHist !== prevprops.netStreamHist) {
      let updated_stream = this.state.stream.concat(this.props.netStreamHist);
      this.setState({ stream: updated_stream });
    }

    if (this.props.netSessionChat !== prevprops.netSessionChat) {
        let updated_stream = this.state.stream.concat(this.props.netSessionChat);
        this.setState({ stream: updated_stream });  
    }

    this.messageWindow.scrollTop = this.messageWindow.scrollHeight;
  }

  componentDidMount() {
    const sessionid = this.props.match.params.sessionid;
    this.setState({ sessionid: sessionid });

    /* send netsession details request as soon as we load */
    try {
      this.props.websocket_api("netSession", `{ "sessionid": "${sessionid}" }`);
    } catch (error) {
      console.log(error);
    }
  }

  setDidCheckIn = (val) => {
    this.setState({ didCheckIn: val });
  };

  toggleSelfCheckin = () => {
    const current = this.state.showSelfCheckin;
    this.setState({ showSelfCheckin: !current });
  };

  isCheckedIn = () => {
    if (this.props.profile) {
      if (
        this.state.netSession.checkins.some(
          (checkin) => {
            return checkin.callsign === this.props.profile.callsign && checkin.isSuggestion === false
          }
        )
      ) {
        return true;
      }
    }
    return false;
  };

  tsLocal = (utc_timestamp) => {
    utc_timestamp = utc_timestamp.replace(' ', 'T')
    var date = new Date(utc_timestamp);
    return date.toDateString();
  };

  render() {
    const isNetOperator = (operators, myuserid) => {
      return operators.includes(myuserid) ? true : false;
    };

    let adminToggle = null;
    if (
      this.props.netSession &&
      this.props.profile &&
      isNetOperator(this.props.netSession.operators, this.props.profile.userid)
    ) {
      adminToggle = (
        <div>
          <Switch
            checked={this.state.showNetOpPanel}
            onChange={() => {
              this.setState((prevState) => ({
                showNetOpPanel: !prevState.showNetOpPanel,
              }));
            }}
            name="switchOpPanel"
            label="NetOp Panel"
          />{" "}
          NetOp Panel
        </div>
      );
    }

    let dmrHoselineDisplay = (
      <div>
        <Chip label=" Talkgroup is IDLE " variant="outlined" />
      </div>
    );

    if (this.state.dmrHoseline) {
      let tg = Object.values(this.state.dmrHoseline)[0];
      if (tg !== null) {
        dmrHoselineDisplay = (
          <div>
            <Chip
              label={"Transmitting: " + tg.callsign}
              variant="outlined"
              color="primary"
            />
          </div>
        );
      } else {
        dmrHoselineDisplay = (
          <div>
            <Chip label=" Talkgroup is IDLE " variant="outlined" />
          </div>
        );
      }
    }

    return (
      <div>
        <CheckinSelfForm
          showSelfCheckin={this.state.showSelfCheckin}
          sessionid={this.state.sessionid}
          toggleSelfCheckin={this.toggleSelfCheckin}
          setDidCheckIn={this.setDidCheckIn}
          websocket_api={this.props.websocket_api}
        />

        <Paper>
          <Toolbar>
            <IconButton
              onClick={() => {
                this.props.history.goBack();
              }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6">
              {this.props.netSession
                ? this.tsLocal(this.props.netSession.ts_open)
                : ""}{" "}
            </Typography>
            <div style={{ flex: 1 }} />
            {dmrHoselineDisplay}
            <Box m={1}>
              <Button
                variant="contained"
                color="primary"
                disabled={this.isCheckedIn() || this.state.didCheckIn}
                onClick={() => {
                  this.toggleSelfCheckin();
                }}
              >
                Check In
              </Button>
            </Box>
          </Toolbar>

          <Toolbar>
            <Tabs
              value={this.state.tabValue}
              onChange={(event, newValue) => {
                this.setState({ tabValue: newValue });
              }}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab icon={<PlaylistAddCheckIcon />} value="roster" />
              <Tab icon={<ChatIcon />} value="chat" />
              <Tab icon={<TimelineIcon />} value="stats" />
            </Tabs>
          </Toolbar>

          {/* ROSTER TAB CONTENTS */}
          <div hidden={this.state.tabValue !== "roster"}>
            <Toolbar style={{ background: "#fafafd" }}>
              Checkins:{" "}
              {this.state.netSession.checkins
                ? this.state.netSession.checkins.filter(function(e) {return e.isSuggestion === false}).length
                : 0}
              {"  "}
              {adminToggle}
            </Toolbar>

            <Collapse in={this.state.showNetOpPanel}>
              <Toolbar style={{ background: "#eaeaed" }}>
                <Box p={2}>
                  <CheckinOTAForm
                    sessionid={this.state.sessionid}
                    websocket_api={this.props.websocket_api}
                  />
                  <CheckinsTable websocket_api={this.props.websocket_api} sessionid={this.state.sessionid} isSuggested={true} checkins={this.state.netSession.checkins} isNetOperator={this.props.netSession && this.props.profile && isNetOperator(this.props.netSession.operators, this.props.profile.userid)} />
                </Box>
              </Toolbar>
            </Collapse>

            <Box p={2}>
              <CheckinsTable websocket_api={this.props.websocket_api} sessionid={this.state.sessionid} isSuggested={false} checkins={this.state.netSession.checkins} isNetOperator={this.props.netSession && this.props.profile && isNetOperator(this.props.netSession.operators, this.props.profile.userid)} />
            </Box>
          </div>

          {/* CHAT TAB CONTENTS */}
          <div hidden={this.state.tabValue !== "chat"}>
            <Box p={2}>
              <div
                ref={(messageWindow) => (this.messageWindow = messageWindow)}
                style={{
                  boxSizing: "border-box",
                  /*border: "1px solid #ccc",*/
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  height: "55vh",
                  overflowY: "auto",
                }}
              >
                {this.state.stream.map((value, index) => {
                  if (value !== null) {
                    if (value.type === 'transmit') {
                      return (
                        <Typography key={index} color="primary">
                          Transmitting: {value.callsign}
                        </Typography>
                      );  
                    }
                    else if (value.type === 'chat') {
                      return (
                        <Typography key={index}>
                          {value.sender}: {value.message}
                        </Typography>
                      );
                    }
  
                  }
                })}
              </div>
              <div>
                <form noValidate autoComplete="off">
                  <Grid container>
                    <Grid item xs={11}>
                      <TextField
                        innerRef={(messageInput) => (this.messageInput = messageInput)}
                        fullWidth={true}
                        placeholder={this.props.profile == null ? "Log in to use chat." : "Enter your chat text here"}
                        disabled={this.props.profile == null ? true : false}
                        variant="outlined"
                        autoFocus
                        value={this.state.message}
                        onChange={(evt) => {
                          this.setState({
                            message: evt.target.value
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} align='right'>
                      <Button
                        type="submit"
                        variant="contained"
                        onClick={(event) => {
                          event.preventDefault()
                          this.props.websocket_api('netSessionChat', '{"message": '+ JSON.stringify(this.state.message) + '}');
                          this.setState({message: ''});
                          this.messageInput.focus()
                        }}
                      >
                        Send
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Box>
          </div>

          {/* STATS TAB CONTENTS */}
          <div hidden={this.state.tabValue !== "stats"}>
            <Box p={2}>
              <br />
              <br />
              <Typography>Stats are coming soon.</Typography>
            </Box>
          </div>
        </Paper>
      </div>
    );
  }
}

export default withRouter(NetSession);
