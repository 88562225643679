import React from 'react'
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';


function Contests() {
    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <Paper style={{padding: 20, maxWidth: 800}}>
                <Typography variant="h2">
                    Working on the scoreboard now!
                </Typography>
                <br/>
                <Typography variant="h5" style={{color:"#888888"}}>
                    The scoring system for DMR contests has already been built. We will award points for making different types of contacts with users in varying circumstances or geographies. The specific rules and point values for our first contest will be rolling out soon. I'll also announce the contest on one of our upcoming Georgia DMR nets on talkgroup 3113. Stay tuned!
                </Typography>
            </Paper>
        </div>
)
}

export default Contests
