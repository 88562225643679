import React from "react";
import "../TextInput-style.css";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import NetList from "./NetList";
import DMRActivityList from "./DMRActivityList";
import NetSession from "./NetSession";
import { Switch, Route } from "react-router-dom";
import UserOnboard from "./UserOnboard";
import Landing from "./Landing";
import Logs from "./Logs"
import Contests from "./Contests"

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      num_matches: 0,
      callsignFound: false,
    };
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  render() {
    let name, avatar;

    if (this.props.callsignLookup !== undefined) {
      name = this.props.callsignLookup.name;
      if (this.props.callsignLookup.num_matches === 1) {
        avatar = (
          <Avatar
            alt="test"
            src={`https://avatars.dicebear.com/api/identicon/${this.props.callsignLookup.name}.svg`}
            style={{ height: 75, width: 75 }}
          />
        );
      } else {
        avatar = null;
      }
    } else {
      name = null;
      avatar = null;
    }

    if (this.props.profile) {
      if (this.props.profile.noProfile || this.props.profile.userOnboardError) {
        if (this.props.auth0.isAuthenticated) {
          return (
            <UserOnboard
              profile={this.props.profile}
              name={this.props.auth0.user.given_name || ""}
              websocket_api={this.props.websocket_api}
              auth0={this.props.auth0}
            />
          );
        }
      }
    }

    return (
      <div>
        <Box p={5}>
          <Switch>
            <Route exact path="/">
              <Landing />
            </Route>

            <Route exact path="/nets">
              <NetList
                websocket_api={this.props.websocket_api}
                netList={this.props.netList}
                profile={this.props.profile}
              />
              &nbsp;&nbsp;
              <DMRActivityList
                websocket_api={this.props.websocket_api}
                dmrActivity={this.props.dmrActivity}
              />

            </Route>

            <Route
              exact
              path="/nets/session/:sessionid"
              render={(userProps) => (
                <NetSession
                  websocket_api={this.props.websocket_api}
                  netSession={this.props.netSession}
                  netSessionCheckin={this.props.netSessionCheckin}
                  netSessionCheckinDelete={this.props.netSessionCheckinDelete}
                  profile={this.props.profile}
                  dmrHoseline={this.props.dmrHoseline}
                  netStreamHist={this.props.netStreamHist}
                  netSessionChat={this.props.netSessionChat}
                />
              )}
            />

            <Route exact path="/logs">
              <Logs
                websocket_api={this.props.websocket_api}
                logQuery={this.props.logQuery}
                logQueryAddOne={this.props.logQueryAddOne}
                profile={this.props.profile}
              />
            </Route>

            <Route exact path="/contests">
              <Contests />
            </Route>
          </Switch>
        </Box>
      </div>
    );
  }
}

export default Home;
