import React from 'react'
import Typography from '@mui/material/Typography';
import LazyHero from 'react-lazy-hero';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import {Alert, AlertTitle} from '@mui/material'


function Landing() {
    return (
        <div>
            <Box sx={{ overflow: 'hidden' }}>
                <LazyHero imageSrc="umberto-jXd2FSvcRr8-unsplash.jpeg" minHeight="50vh" color="#5555cc" opactity={0.2} parallaxOffset={50} transitionDuration={1500} transitionTimingFunction="ease-in-out" isCentered={true}>
                    <Typography variant="h2" style={{color:"#ffffff"}}>A modern toolkit for amateur radio operators.</Typography>
                </LazyHero>
            </Box>
            <br/>

            <Alert severity="info" variant="outlined">
                <AlertTitle>Latest Updates</AlertTitle>
                NetQSO has big plans for supporting the amateur radio community in 2022. Please use the link at the bottom right of the page to send ideas, bugs, and other feedback.
            </Alert>

            <br/>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Paper style={{padding: 20, maxWidth: 800}}>
                    <Typography variant="h2">
                        Logging as simple as pencil and paper
                    </Typography>
                    <br/>
                    <Typography variant="h5" style={{color:"#888888"}}>
                        Capture necessary log fields with friendly input boxes. Spend less time logging and more time focusing on your QSO. Import ADIF logfiles from other logging tools and view your logs with insightful visualizations.
                    </Typography>
                    <br/>
                    <Alert severity="info">
                        <AlertTitle>Coming soon</AlertTitle>
                        Predictive input, better reporting and propagation analysis, more modes and input templates, export your logs, and more
                    </Alert>
                </Paper>
            </div>

            <br/><br/><br/>
            <div style={{display: 'flex', justifyContent: 'center'}}>

                <Paper style={{padding: 20, maxWidth: 800}}>
                    <Typography variant="h2">
                        Nets should be easy
                    </Typography>
                    <br/>
                    <Typography variant="h5" style={{color:"#888888"}}>
                        Check out the net schedule for opportunities to connect with amateur radio operators around the world.
                    </Typography>
                    <br/>
                    <Alert severity="info">
                        <AlertTitle>Coming soon</AlertTitle>
                        More nets (add your own), recommended nets for you, net owner/operator configurations, topics/announcements, and lots of stats
                    </Alert>
                </Paper>
            </div>

            <br/><br/><br/>
            <div style={{display: 'flex', justifyContent: 'center'}}>

                <Paper style={{padding: 20, maxWidth: 800}}>
                    <Typography variant="h2">
                        How about a little friendly competition?
                    </Typography>
                    <br/>
                    <Typography variant="h5" style={{color:"#888888"}}>
                        <>In 2022, NetQSO is rolling out contests! Our first contest mode is focused on DMR talkgroups on the Brandmeister network. We'll be monitoring that activity live so there's no need to track your contacts&mdash;we're already doing it. You can monitor progress live on the scoreboard but you don't have to do anything else. Just do what you like to do&mdash;talk on the radio. Let's keep it simple, right?</>
                    </Typography>
                </Paper>
            </div>

        </div>
    )
}

export default Landing
