import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from "@auth0/auth0-react";

import App from './App';

ReactDOM.render(
    <BrowserRouter>
      <Auth0Provider domain="dev-clek06u8.us.auth0.com" clientId="upLBpm1q37X49SyIn49oZ8aXzGeFEsOK" redirectUri={window.location.origin} >
        <App />
      </Auth0Provider>
    </BrowserRouter>,
    document.getElementById('root'),
);

if (module.hot) module.hot.accept();