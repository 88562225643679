import React from "react";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import UserOnboardCallsignForm from "./UserOnboardCallsignForm.js"

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography component="span" variant="h6" display="block">
        {children}
      </Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const UserOnboardError = (props) => {
    console.log('check profile...')
    console.log(props.profile)
    if (props.profile) {
        if (props.profile.userOnboardError) {
            return <Typography>{props.profile.userOnboardError}</Typography>
        }
    }
    return <Typography />
}


const UserOnboard = (props) => {
    console.log('profile check one-two:')
    console.log(props.profile)
  return (
    <span>
      <Dialog open>
        <DialogTitle id="customized-dialog-title">Welcome!</DialogTitle>
        <DialogContent dividers>
            <Typography component="span" display="block">
            You are one of the first ham operators to join our community. Thank you. We're operating in alpha here and things
            may change quite a bit.<br/><br/>
            For now, I just need your callsign and a friendly name that you'd like displayed to other operators. While you 
            can check into nets via the website without registering as a user, there are many features that will require 
            registration in the future so please encourage others to join us if you're enjoying the site. 
            </Typography>
            <br/><br/>
            <UserOnboardCallsignForm name={props.name} websocket_api={props.websocket_api} auth0={props.auth0} />
            <UserOnboardError profile={props.profile} />
        </DialogContent>
      </Dialog>
    </span>
  );
};

export default withStyles(styles)(UserOnboard);
