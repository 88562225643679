import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { TextField, CheckboxWithLabel, RadioGroup } from "formik-mui";
import Button from "@mui/material/Button";
import { Radio } from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";


class CheckinSelfForm extends Component {
  validate_form(values) {
    const errors = {};
    if (!values.callsign) {
      errors.callsign = "Callsign is required";
    }
    if (!values.name) {
      errors.name = "Name is required";
    }
    if (!values.via) {
      errors.via = "The connection mode is required.";
    }
    return errors;
  }

  render() {
    const form_submit = (values, { setSubmitting, resetForm }) => {
      values["sessionid"] = this.props.sessionid;
      values["isSuggestion"] = false

      try {
        this.props.websocket_api(
          "netSessionCheckin",
          JSON.stringify(values, null, 2)
        );
      } catch (error) {
        console.log(error);
        return;
      }
      resetForm({});
      setSubmitting(false);
      this.props.setDidCheckIn(true);
      this.props.toggleSelfCheckin();
    };

    return (
      <Dialog open={this.props.showSelfCheckin}>
        <Formik
          initialValues={{
            callsign: "",
            name: "",
            location: "",
            short_time: false,
            via: "Hotspot",
            comments: "",
          }}
          validate={this.validate_form}
          onSubmit={form_submit}
        >
          {({ isSubmitting, errors, touched }) => (
            <Box p={5}>
              <Form>
              <Box p={1}>
                  <Field
                    name="callsign"
                    label="Callsign"
                    component={TextField}
                  />
                </Box>
                <Box p={1}>
                  <Field
                    name="name"
                    label="Name"
                    component={TextField}
                  />
                </Box>
                <Box p={1}>
                  <Field
                    name="location"
                    label="Location"
                    component={TextField}
                  />
                </Box>
                <Box p={1}>
                  <Field component={RadioGroup} name="via">
                    <FormLabel component="legend">Connection</FormLabel>
                    <FormControlLabel
                      value="Hotspot"
                      control={<Radio />}
                      label="Hotspot"
                      disabled={isSubmitting}
                    />
                    <FormControlLabel
                      value="Repeater"
                      control={<Radio />}
                      label="Repeater"
                      disabled={isSubmitting}
                    />
                    <FormControlLabel
                      value="Network Radio"
                      control={<Radio />}
                      label="Network Radio"
                      disabled={isSubmitting}
                    />
                  </Field>
                </Box>
                <Box p={1}>
                  <Field
                    name="comments"
                    label="Comments"
                    component={TextField}
                  />
                </Box>
                <Box p={1}>
                  <Field
                    name="short_time"
                    type="checkbox"
                    component={CheckboxWithLabel}
                    Label={{ label: "Short Time" }}
                  />
                </Box>
                <Box p={1}>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </Button>
                  &nbsp;
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.props.toggleSelfCheckin()
                    }}
                  >
                    Cancel
                  </Button>

                </Box>
              </Form>
            </Box>
          )}
        </Formik>
      </Dialog>
    );
  }
}

export default CheckinSelfForm;
