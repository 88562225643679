import React, { Fragment } from "react";
import { CssBaseline } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
//import { useTheme } from "@mui/material/styles"
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import Home from "./components/Home";
import socketIOClient from "socket.io-client";
import { withAuth0 } from "@auth0/auth0-react";
import { BrowserRouter as Router } from "react-router-dom";
import { createTheme } from "@mui/system";


const ENDPOINT_DEV = "http://netqso-api:8000" /* this is an alias in /etc/hosts */
const ENDPOINT_PROD = "https://netqso.com"

//const styles = makeStyles((theme) => ({
//  main: {
    //padding: theme.spacing(3),
    //[theme.breakpoints.down("xs")]: {
    //  padding: theme.spacing(2),
//    },
//  }));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.websocket_api = this.websocket_api.bind(this);

    if (process.env.NODE_ENV === 'development') { this.socket = socketIOClient(ENDPOINT_DEV) }
    else {
      console.log('connecting to socket...')
      this.socket = socketIOClient(ENDPOINT_PROD)
      console.log(this.socket)
    }

    this.state = {
      callsignDetails: null,
      auth_token: null,
      logQuery: null,
      logQueryAddOne: null,
      netList: null,
      gotProfile: false,
      profile: null,
      dmrHoseline: null,
      dmrActivity: null,
      netStreamHist: null,
      netSessionChat: null,
    };

    this.already_popped = false;
  }

  componentDidMount() {

    this.socket.on('connect', (data) => {
      console.log('connected to socket')
    });

    this.socket.on('connecting', (data) => {
      console.log('connecting to socket')
    });

    this.socket.on('error', (data) => {
      console.log('socket error:')
      console.log(data)
    });

    this.socket.on('reconnecting', (data) => {
      console.log('reconnecting to socket')
      console.log(data)
    });

    this.socket.on('reconnected', (data) => {
      console.log('reconnected to socket')
    });

    this.socket.on("connect_failed", (data) => {
      console.log('connect failed:')
      console.log(data)
    });

    this.socket.on("callsignLookup", (data) => {
      this.setState({ callsignLookup: JSON.parse(data) });
    });

    this.socket.on("logQuery", (data) => {
      this.setState({ logQuery: JSON.parse(data) });
    });

    this.socket.on('logQueryAddOne', (data) => {
      console.log('got it at the top!') // debug
      this.setState({ logQueryAddOne: JSON.parse(data) })
    })
  
    this.socket.on("netList", (data) => {
      this.setState({ netList: JSON.parse(data) });
    });

    this.socket.on("netSession", (data) => {
      this.setState({ netSession: JSON.parse(data) });
    });

    this.socket.on("netSessionCheckin", (data) => {
      this.setState({ netSessionCheckin: JSON.parse(data) });
    });

    this.socket.on("netSessionCheckinDelete", (data) => {
      this.setState({ netSessionCheckinDelete: JSON.parse(data) });
    });

    this.socket.on("getProfile", (data) => {
      this.setState({ profile: JSON.parse(data) });
    });

    this.socket.on("dmrHoseline", (data) => {
      this.setState({ dmrHoseline: JSON.parse(data) })
    })

    this.socket.on("dmrActivity", (data) => {
      this.setState({ dmrActivity: JSON.parse(data) })
    })

    this.socket.on("netStreamHist", (data) => {
      this.setState({ netStreamHist: JSON.parse(data) });
    })

    this.socket.on("netSessionChat", (data) => {
      this.setState({ netSessionChat: JSON.parse(data) });
    })

  }

  websocket_api(mode, message) {
    console.log("Emitting [" + mode + "] " + message);
    this.socket.emit(mode, JSON.stringify(message));
  }

  send_auth_token(token) {
    this.websocket_api("apiToken", `{ "auth_token": "${token}" }`)  
  }

  async get_api_auth_token() {
    
    let auth_token = ''
    if (process.env.NODE_ENV === 'development') {
      /* you can't use silent token grabs from localhost.... so use a popup during dev */
      /*this.props.auth0.getAccessTokenWithPopup({ audience: "https://netqso.com/ws" }).then(token => {*/
      try {
        this.props.auth0.getAccessTokenSilently().then(token => {  
          console.log('token: ', token)
          this.send_auth_token(token)
        })
      } catch (error) {
        console.log('error: ', error)
      }
    } else {
      try {
        /*this.props.auth0.getAccessTokenWithPopup({ audience: "https://netqso.com/ws" }).then(token => {*/
        this.props.auth0.getAccessTokenSilently().then(token => {
          console.log('token: ', token)
          this.send_auth_token(token)
        })  
      } catch (error) {
        console.log('error: ', this.props.auth0.error)
      }
    }
  }

  render() {
    /* check if we're authenticated. if so, grab our profile and get an API token if we don't have one. */
    if (this.props.auth0 !== undefined) {
      if (this.props.auth0.isAuthenticated) {
        if (this.state.auth_token === null) {
          if (!this.already_popped) {
            this.get_api_auth_token();
            this.already_popped = true;
          }
        }
      }
    }
    
    return (
      <Fragment>
        <CssBaseline />
        
        <Router>
          <AppHeader profile={this.state.profile} auth0={this.props.auth0} />
        
          <Home
            websocket_api={this.websocket_api}
            callsignLookup={this.state.callsignLookup}
            logQuery={this.state.logQuery}
            logQueryAddOne={this.state.logQueryAddOne}
            netList={this.state.netList}
            netSession={this.state.netSession}
            netSessionCheckin={this.state.netSessionCheckin}
            netSessionCheckinDelete={this.state.netSessionCheckinDelete}
            profile={this.state.profile}
            auth0={this.props.auth0}
            dmrHoseline={this.state.dmrHoseline}
            dmrActivity={this.state.dmrActivity}
            netStreamHist={this.state.netStreamHist}
            netSessionChat={this.state.netSessionChat}
          />
        
          <AppFooter />
        </Router>
      </Fragment>
    );
  }
}

export default withAuth0(App);
