import React, {useEffect, Fragment} from 'react'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { FormGroup } from '@mui/material'
import { FormControlLabel } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination'
import {Toolbar, Tabs, Tab} from '@mui/material'
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from 'react-simple-maps';
import moment from 'moment';
import { DropzoneArea } from 'react-mui-dropzone';
import ShortTextIcon from '@mui/icons-material/ShortText'
import Tooltip from '@mui/material/Tooltip'


const geoUrl = 'https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json'




function Logs(props) {

    const [callsign, setCallsign] = React.useState('');
    const handleCallsignChange = (event) => {
      setCallsign(event.target.value);
    };

    const [freq, setFreq] = React.useState('');
    const handleFreqChange = (event) => {
      setFreq(event.target.value);
    };
    
    const [mode, setMode] = React.useState('');
    const handleModeChange = (event) => {
      setMode(event.target.value);
    };
    
    const [locstr, setLocStr] = React.useState('');
    const handleLocStrChange = (event) => {
      setLocStr(event.target.value);
    };

    const [rstReceived, setRstReceived] = React.useState('59');
    const handleRstReceivedChange = (event) => {
      setRstReceived(event.target.value);
    };

    const [rstSent, setRstSent] = React.useState('59');
    const handleRstSentChange = (event) => {
      setRstSent(event.target.value);
    };

    const [logdate, setLogdate] = React.useState(new Date().toUTCString());
    const handleLogdateChange = (event) => {
      setLogdate(event.target.value);
    };

    const [notes, setNotes] = React.useState('');
    const handleNotesChange = (event) => {
      setNotes(event.target.value);
    };

    const [power, setPower] = React.useState('');
    const handlePowerChange = (event) => {
      setPower(event.target.value);
    };

    const [useCurrentTime, setUseCurrentTime] = React.useState(true);
    const handleUseCurrentTimeChange = (event) => {
      setUseCurrentTime(Boolean(event.target.checked));
    };

    // Log view and pagination
    const [log, setLog] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [tabValue, setTabValue] = React.useState('entry');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        setLog(props.logQuery)
    }, [props.logQuery])


    useEffect(() => {
        console.log(log)
        console.log(props.logQueryAddOne)
        if (props.logQueryAddOne) {
            const tmp = [...log || [], props.logQueryAddOne]
            tmp.sort((a, b) => (a.utc_ts < b.utc_ts) ? 1 : -1)
            console.log('new array:')
            console.log(tmp)
            setLog(tmp)
        }
    }, [props.logQueryAddOne])


    useEffect(() => {
        props.websocket_api('logQuery', '{}')
    }, [])


    const handleLogEntrySubmit = () => {
        const entry = {
            'contact': callsign,
            'freq': freq,
            'mode': mode,
            'locstr': locstr,
            'rstRecieved': rstReceived,
            'rstSent': rstSent,
            'notes': notes,
            'power': power,
            'useCurrentTime': useCurrentTime
        }
        if (props.profile && entry.contact) {
            props.websocket_api('logEntryAdd', entry)
        }
    }

    const tsLocalDate = (utc_timestamp) => {
        utc_timestamp = utc_timestamp.replace(' ', 'T')
        var date = new Date(utc_timestamp);
        return date.toDateString();
    };

    const tsLocalTime = (utc_timestamp) => {
        utc_timestamp = utc_timestamp.replace(' ', 'T')
        var date = new Date(utc_timestamp);
        return date.toTimeString();
    };

    return (
        <div>            
            <Toolbar>
                <Tabs
                value={tabValue}
                onChange={(event, newValue) => {
                    setTabValue(newValue);
                }}
                indicatorColor="primary"
                textColor="primary"
                centered
                >
                    <Tab label="Entry" value="entry" />
                    <Tab label="History" value="history" />
                    <Tab label="Summary" value="summary" />
                    <Tab label="Upload" value="upload" />
                </Tabs>
            </Toolbar>

            <div hidden={tabValue !== "entry"}>
                <Box display="flex" justifyContent="center">
                    <Paper style={{backgroundColor: "#eeeeee", margin: 2, padding: 2, maxWidth: 800}}>
                        <Box m={2}>
                        <Typography variant="h5">Logbook Entry</Typography>
                            <form noValidate autoComplete="off">
                                <Grid container>
                                    
                                    <Grid item xs={6} sm={4}>
                                        <TextField id="log-callsign" label="Callsign" value={callsign} variant="outlined" margin="dense" onChange={handleCallsignChange} style={{backgroundColor: "#f2f2f2"}}/>
                                    </Grid>
                                    
                                    <Grid item xs={8} sm={4}>
                                        <TextField id="log-rst-received" label="RST Received" value={rstReceived} variant="outlined" margin="dense" onChange={handleRstReceivedChange} inputProps={{ maxLength: 3 }} style={{backgroundColor: "#f2f2f2"}} />        
                                    </Grid>
                                    
                                    <Grid item xs={8} sm={4}>
                                        <TextField id="log-rst-sent" label="RST Sent" value={rstSent} variant="outlined" margin="dense" onChange={handleRstSentChange} inputProps={{ maxLength: 3 }} style={{backgroundColor: "#f2f2f2"}} />        
                                    </Grid>
                                    
                                    <Grid item xs={8} sm={4}>
                                        <TextField id="log-location-received" label="Location" value={locstr} placeholder="Grid or Loc Name" variant="outlined" margin="dense" onChange={handleLocStrChange} style={{backgroundColor: "#f2f2f2"}} />        
                                    </Grid>
                                    
                                    <Grid item xs={6} sm={4}>
                                        <TextField id="log-freq" label="Frequency" value={freq} variant="outlined" margin="dense" onChange={handleFreqChange} style={{backgroundColor: "#f2f2f2"}} />
                                    </Grid>
                                    
                                    <Grid item xs={6} sm={4}>
                                        <TextField select id="log-mode" label="Mode" value={mode} variant="outlined" margin="dense" onChange={handleModeChange} style={{backgroundColor: "#f2f2f2", width: 150}}>
                                            <MenuItem value="FM">FM</MenuItem>
                                            <MenuItem value="SSB">SSB</MenuItem>
                                            <MenuItem value="CW">CW</MenuItem>
                                            <MenuItem value="FT-8">FT-8</MenuItem>
                                            <MenuItem value="PSK-13">PSK-13</MenuItem>
                                            <MenuItem value="RTTY">RTTY</MenuItem>
                                        </TextField>
                                    </Grid>        
                                    
                                    <Grid item xs={8} sm={4}>
                                        <TextField id="log-notes" label="Personal Notes" value={notes} variant="outlined" margin="dense" onChange={handleNotesChange} multiline rows={3} style={{backgroundColor: "#f2f2f2"}} />                
                                    </Grid>
                                    
                                    <Grid item xs={8} sm={4}>
                                        <TextField id="log-power" label="Power (w)" value={power} variant="outlined" margin="dense" onChange={handlePowerChange} style={{backgroundColor: "#f2f2f2"}} />
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={4}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox checked color="primary" checked={useCurrentTime} onChange={handleUseCurrentTimeChange} />}
                                                label="Use current time" />
                                        </FormGroup>
                                        {/*<TextField id="log-datetime" label="UTC Time" variant="outlined" margin="dense" value={logdate} onChange={handleLogdateChange} style={{backgroundColor: "#f2f2f2", width: 350}}/>*/}

                                        { props.profile ?
                                            <Button variant="contained" color="primary" onClick={handleLogEntrySubmit}>Save to my log</Button> :
                                            <Button disabled={true} variant="contained" color="primary" onClick={handleLogEntrySubmit}>Log in to save</Button>
                                        }
                                    </Grid>

                                </Grid>
                            </form>
                        </Box>
                    </Paper>
                </Box>
            </div>
            
            <div hidden={tabValue !== "history"}>
                <Box display="flex" justifyContent="center">
                    <div style={{backgroundColor: "#ffffff", margin: 0, padding: 0, maxWidth: 800}}>
                        <TableContainer component={Paper} m={3} style={{width: 800}}>
                            <Table>
                                <TableHead>
                                <TableRow>
                                    <TableCell>Callsign</TableCell>
                                    <TableCell align="left">Location</TableCell>
                                    <TableCell align="left">Freq/Mode</TableCell>
                                    <TableCell align="left">Time</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                    { log ? 
                                        log.sort((a, b) => (a.utc_ts > b.utc_ts) ? -1 : 1).slice(page*rowsPerPage, page*rowsPerPage+rowsPerPage).map((row) => {                                        
                                            console.log(row)
                                            return (
                                                <TableRow key={row._id}>
                                                    <TableCell align="left">{row.contact}<br/>{row.notes ? <Tooltip title={row.notes}><ShortTextIcon fontSize='small' color='disabled'/></Tooltip> : null}</TableCell>
                                                    <TableCell align="left">{row.location && row.location.flag ? row.location.flag : null}{' '}{row.location && row.location.country ? row.location.country : null}<br/>{row.location && row.location.city ? row.location.city : null}{row.location && row.location.state ? ', '+ row.location.state : null}<br/>{row.location && row.location.grid ? row.location.grid : null}</TableCell>
                                                    <TableCell align="left">{row.freq ? row.freq : null}<br/>{row.mode ? row.mode: null}<br/>{row.freqdesc}</TableCell>
                                                    <TableCell align="left">{row.utc_ts ? moment(row.utc_ts).fromNow() : null}<br/>{row.utc_ts ? tsLocalDate(row.utc_ts) : null}<br/>{row.utc_ts ? tsLocalTime(row.utc_ts) : null}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                        : null
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={log ? log.length : 0}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPage={rowsPerPage}
                        />
                    </div>
                </Box>
            </div>

            <div hidden={tabValue !== "summary"}>
                <center>
                    <Paper style={{backgroundColor: "#ffffff", margin: 1, padding: 1, maxWidth: 800}}>
                        <ComposableMap>
                            <ZoomableGroup zoom={1}>
                                <Geographies geography={geoUrl}>
                                    {({ geographies }) =>
                                        geographies.map(geo => <Geography key={geo.rsmKey} geography={geo} />)
                                    }
                                </Geographies>
                            
                                    { log ?
                                        log.map((l) => (
                                            (l.contact) && (l.location) && (l.location.lat) && (l.location.lng) ?
                                                <Marker key={l.contact+l.utc_ts} coordinates={[l.location.lng, l.location.lat]}>
                                                    <circle r={2.50} fill="#F00" stroke="#fff" strokeWidth={0.06} />
                                                </Marker>
                                            : null
                                        ))
                                    : null
                                    }
                            </ZoomableGroup>
                        </ComposableMap>
                    </Paper>
                </center>
            </div>

            <div hidden={tabValue !== "upload"}>
                <center>
                    <Paper style={{backgroundColor: "#ffffff", margin: 1, padding: 1, maxWidth: 800}}>
                        <DropzoneArea dropzoneText="Drag and drop your ADIF file here or click to browse"
                        acceptedFiles={['.adi', '.adif']} showAlerts={true} onChange={(files) => {
                            console.log('Files:', files[0])
                            files.forEach((file) => {
                                var reader = new FileReader();
                                reader.onabort = () => console.log('file reading was aborted')
                                reader.onerror = () => console.log('file reading has failed')
                                reader.onload = () => {
                                    const base64Str = reader.result.split(',')[1]
                                    console.log(base64Str)
                                    props.websocket_api('logUpload', base64Str)
                                }
                                reader.readAsDataURL(file)
                            })
                        }} />
                    </Paper>
                </center>
            </div>
            
        </div>
    )
}


export default Logs