import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Toolbar from "@mui/material/Toolbar";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import CircularProgress from '@mui/material/CircularProgress'
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
var _ = require("lodash");



class DMRActivityList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      dmrActivity: {},
    };
  }s

  componentDidUpdate(prevprops) {
    /* we have an update... let's see if it's a dmr activity update and apply it to state */
    if (this.props.dmrActivity != prevprops.dmrActivity) {
        var updated = this.state.dmrActivity
        updated[this.props.dmrActivity['tg']] = this.props.dmrActivity
        this.setState({ dmrActivity: updated });
    }
  }

  componentDidMount() {
    /* send netlist request as soon as we load */
    try {
      this.props.websocket_api("dmrActivityOn", '{}');
    } catch (error) {
      console.log(error);
    }
  }

  componentWillUnmount() {
    try {
        this.props.websocket_api("dmrActivityOff", '{}');
    } catch (error) {
        console.log(error);
    } 
  }

  render() {


    /* check props for incoming DMR Activity list updates */
    if (this.state.dmrActivity) {
      return (
        <Paper>
          <Toolbar>
            <Typography>Talkgroup Activity</Typography>
          </Toolbar>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                    {
                        Object.keys(this.state.dmrActivity).length > 0 ? (
                            Object.keys(this.state.dmrActivity).map((a) => {
                                return (
                                    <TableRow key={a}>
                                        <TableCell width="40%">{a}</TableCell>
                                        <TableCell width="60%" style={{ paddingBottom: 0, paddingTop: 0 }}>
                                            {Object.keys(this.state.dmrActivity[a].recent).map((r) => {
                                                return (
                                                    <Chip label={r} color="primary" size="small" key={a+r}/>
                                                )
                                            })}
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        ) : (
                            <TableRow>
                                <TableCell align="center">
                                    <CircularProgress /><br/>
                                    Listening for activity
                                </TableCell>
                            </TableRow>
                        )
                    }
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      );
    }

    return null;
  }
}

export default withRouter(DMRActivityList);
