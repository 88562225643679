import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Typography } from "@mui/material";

class UserOnboardCallsignForm extends Component {

  validate_form(values) {
    const errors = {};
    if (!values.callsign) {
      errors.callsign = "Callsign is required";
    }
    if (!values.name) {
      errors.name = "Name is required";
    }
    return errors;
  }

  render() {
    const form_submit = (values, { setSubmitting }) => {
      console.log(JSON.stringify(values, null, 2));

      try {
        this.props.websocket_api(
          "userProfileUpdate",
          JSON.stringify(values, null, 2)
        );
      } catch (error) {
        console.log(error);
      }

      setSubmitting(false);
    };

    return (
      <div>
        <Formik
          initialValues={{
            callsign: "",
            name: this.props.name,
          }}
          validate={this.validate_form}
          onSubmit={form_submit}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form>
              <Typography component="span" variant="h6" display="block">
                Callsign
              </Typography>
              
              <br/>

                <Field type="text" name="callsign" />
                {errors.callsign && touched.callsign ? (
                  <ErrorMessage name="callsign" component="div" />
                ) : null}

              <br/><br/>

              <Typography component="span" variant="h6" display="block">
                What should we call you?
              </Typography>
            
              <br/>

                <Field type="text" name="name" />
                {errors.name ? (
                  <ErrorMessage name="name" component="div" />
                ) : null}
              
              <br/><br/>

              <button type="submit" disabled={isSubmitting}>
                Submit
              </button>
              &nbsp;
              <button onClick={this.props.auth0.logout}>Cancel (Logout)</button>
            
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default UserOnboardCallsignForm;
